<template>
  <div>
    <ol class="list_box">
      <!-- <van-list ref="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
        <li class="list" v-for="(itemone, index) in list" :key="index">
          <div class="head_bar">
            <van-image  class="photo" round fit="cover" :src="itemone.postAuthor.avatar_url" />
            <span  class="name">{{itemone.postAuthor.author}} <span v-if="itemone.wonLotterySts===1" style="color:#fff;background-color:red;font-size:0.4rem;">中</span></span>
            
            <div class="icon_box">
              <div class="icon">
                <van-icon name="warn-o" size="20" color="#999" />
              </div>
              <div class="icon collect" @click="goCollect(itemone)">
                <van-icon name="star-o" size="20" color="#999" />
              </div>
            </div>
          </div>
        <p v-if="isSVIP23||isPaid(itemone.forumId)" style="font-size: 0.4rem;text-align: right;color: #f5670be8;">VIP免费</p>
        <p>
            第{{itemone.periodText}}
            <span class="postTime">{{itemone.postTime | setDateMMdd}}</span><span v-if="itemone.roi>0" class="rateReturn">回报率:{{itemone.roi}}%</span>
            
            
        </p>
        <p>
            <span v-if="itemone.lotteryContent!==null">开奖结果：<span class="red"  >{{ itemone.lotteryContent.slice(0,4) }}<span class="black">{{ itemone.lotteryContent.slice(4) }}</span></span></span>
            <span v-else >待开奖</span>
            <span class="red" style="margin-left: 20px;">{{ itemone.lotteryKind===5?'排列五':'七星彩' }}</span>
        </p>
          <div v-if="(itemone.points===null||itemone.points===0)||(userId===itemone.postAuthorUserId)||isSVIP23||isPaid(itemone.forumId)||isOutmoded(itemone.lotteryDate)" class="contents van-hairline--bottom">
            
            <div  >
              <div>
                <div v-if="itemone.contents!==null" v-html="itemone.contents"></div>
                <div v-for="(ite,inde1) in itemone.forumDetailsModels" :key="inde1+'k'">
                  
                  <div v-if="ite.beAppend===0||ite.beAppend===null">
                    <p :class="ite.wonLotterySts===1?'red':'black'">[{{ite.categoryName}}]</p>
                    <div v-if="ite.categoryName==='杀头'||ite.categoryName==='杀百'||ite.categoryName==='杀十'||ite.categoryName==='杀尾'">
                      <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                          千位：
                          <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                          百位：
                          <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                          十位：
                          <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                          个位：
                          <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='死数'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='稳码'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='头尾合'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='中肚合'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='二字现'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_twoshow.includes(item)?'red':'black'">
                            <span v-if="index===ite.textIndexPointGeneral.split(',').length-1">{{item}}</span>
                            <span v-else>{{item}},</span>
                          </span> 
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='三字现'">
                      <p style="word-break:break-word;" v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_threeshow.includes(item)?'red':'black'">
                            <span v-if="index===ite.textIndexPointGeneral.split(',').length-1">{{item}}</span>
                            <span v-else>{{item}},</span>
                          </span> 
                          
                      </p>
                    </div>
                    <div v-else>
                      <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                          千位：
                          <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                          百位：
                          <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                          十位：
                          <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                          个位：
                          <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                  </div>
                </div>
                 <!-- 照片 -->
                <div class="photo_box" v-for="(rows, i1) in itemone.imagesUrlArray" :key="i1+'m'">
                  <van-image v-show="rows.count===1" class="photo"  fit="cover" v-for="(row, j) in rows.imgs.split(',')" :key="j + 1000000" :src="row" @click="goImagePreview(rows.imgs.split(','), j )" />
                </div>
              </div>
              <div>
                <p v-if="itemone.forumDetailsModels.filter(item =>{ if( item.beAppend===1)return item.beAppend}).length!==0"><span style="background-color:#80808033;color:gray;">追加发帖（{{itemone.forumDetailsModels.filter(item =>{ if( item.beAppend===1)return item.beAppend})[0].createdTime | setDateyyyyMMDD}}）</span></p>
                <div v-for="(ite,inde2) in itemone.forumDetailsModels.filter(item =>{ if( item.beAppend===1){return item}})" :key="inde2+'i'">
                  <div v-if="ite.beAppend===1">
                    <p :class="ite.wonLotterySts===1?'red':'black'">[{{ite.categoryName}}]</p>
                    <div v-if="ite.categoryName==='杀头'||ite.categoryName==='杀百'||ite.categoryName==='杀十'||ite.categoryName==='杀尾'">
                      <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                          千位：
                          <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                          百位：
                          <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                          十位：
                          <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                          个位：
                          <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='死数'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='稳码'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='头尾合'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='中肚合'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='二字现'">
                      <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_twoshow.includes(item)?'red':'black'">
                            <span v-if="index===ite.textIndexPointGeneral.split(',').length-1">{{item}}</span>
                            <span v-else>{{item}},</span>
                          </span> 
                      </p>
                    </div>
                    <div v-else-if="ite.categoryName==='三字现'">
                      <p style="word-break:break-word;" v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                        
                          <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_threeshow.includes(item)?'red':'black'">
                            <span v-if="index===ite.textIndexPointGeneral.split(',').length-1">{{item}}</span>
                            <span v-else>{{item}},</span>
                          </span> 
                          
                      </p>
                    </div>
                    <div v-else>
                      <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                          千位：
                          <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                          百位：
                          <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                          十位：
                          <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                      <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                          个位：
                          <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                          <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                            主攻
                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                              重点
                              <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                            </span>
                          </span>
                      </p>
                    </div>
                  </div>
                </div>
                
                <div class="photo_box" v-for="(rows, i2) in itemone.imagesUrlArray" :key="i2+'j'">
                  <van-image v-show="rows.count===2" class="photo"  fit="cover" v-for="(row, j) in rows.imgs.split(',')" :key="j + 1000000" :src="row" @click="goImagePreview(rows.imgs.split(','), j )" />
                </div>
              </div>
              
            </div>
           
          </div>
          <div v-else style="border:1px dashed red;border-radius: 10px;" >
            <p style="font-size: 0.4rem;text-indent: 0.3rem;">本站内容有<span v-for="ite in itemone.forumDetailsModels" :key=ite.categoryId>【{{ite.categoryName}}】、</span> 数据预测</p>
            <h3 style="text-align: center;margin: 0.5rem 0;">注：码无绝对，请自愿参考，量力而为</h3>
            <p style="font-size: 0.4rem;text-indent: 0.3rem;color: red;">声明：本平台所发布的任何预测信息都仅作为娱乐参考，不可作为购彩依据，如因此购彩产生任何纠纷均与本平台无关！</p>
            <p style="font-size: 0.5rem;text-align: center;color: red;line-height: 1rem;">月亮🌛:{{itemone.points}}</p>
            <button style="text-align: center;margin: 0.1rem 3rem;color: white;background-color: red;border-radius: 10px;border: none;width: 3rem;" @click="topay(itemone.forumId)">立即打赏</button>
          </div>
          <div class="foot_bar">
            <div :class="itemone.upCount == '1' ? 'icon Selected' : 'icon'" @click="goThumbsSts(itemone, index)">
              <van-icon name="good-job-o" size="24" color="#999" />
              {{itemone.upCount || 0}}
            </div>
            <div class="icon" @click="goShare()" >
              <van-icon name="share-o" size="24" color="#999" />
              {{itemone.shareCount || 0}}
            </div>
            <div class="icon">
              <van-icon name="comment-o" size="24" color="#999" />
              {{itemone.commentCount || 0}}
            </div>
          </div>
        </li>
      <!-- </van-list> -->
    </ol>
  </div>
</template>

<script>
import wxShare from "@/assets/js/util/wxShare.js";
import { ImagePreview } from 'vant';
import Moment from 'moment'


export default {
    props:['list'],
    data(){
        return{
            access_token: this.$store.state.common.loginInfo.access_token,
            isSVIP23:false,
            userId: this.$store.state.common.loginInfo.userId,
            systemId:'100001',
            finished:true,
            // access_token: this.$store.state.common.loginInfo.access_token,

        }
    },
    filters: {
        setDateMMdd(vle) {
        // if (vle) { return Moment(vle).format('MM月DD') }
        // return '';
        var dateTime = new Date(vle)
        var nolnew = dateTime.valueOf()
        var year = dateTime.getFullYear()
        var month = dateTime.getMonth()+1
        var day = dateTime.getDate()
        var hour = dateTime.getHours()
        var minute = dateTime.getMinutes()
        var second = dateTime.getSeconds()
        var now = new Date()
        var now_new = now.valueOf()
        var milliseconds = 0
        var timeSpanStr;
        milliseconds = now_new - nolnew
        if(milliseconds <= 1000 * 60){
            timeSpanStr='刚刚'
        }else if(1000 * 60 < milliseconds && milliseconds <= 1000 * 60 * 60){
            timeSpanStr= Math.round((milliseconds/(1000 * 60)))+'分钟前'

        }else if(1000 * 60 * 60 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24){
            timeSpanStr= Math.round((milliseconds/(1000 * 60 * 60)))+'小时前'

        }else if(1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 30){
            timeSpanStr= Math.round((milliseconds/(1000 * 60 * 60 * 24)))+'天前'

        }else if(1000 * 60 * 60 * 24 * 30 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 365 ){
            timeSpanStr= parseInt((milliseconds/(1000 * 60 * 60 * 24 * 30)))+'月前'

        }else{
            timeSpanStr = year + '-' + month + '-' + day

        }
        return timeSpanStr;
        },
        setDateyyyyMMDD(vle){
        if (vle) { return Moment(vle).format('yyyy-MM-DD mm:ss') }
        return '';
        }
    },
    methods:{
       //支付
    async topay(formId){
      const res = await this.$HTTP.get(this, this.$API['API_PAY_FORUM_POINT'], {forum_id :formId,user_id:this.userId}, { headers: { access_token : this.$store.state.common.loginInfo.access_token } },true)
      if(res.statusCode == 200){
        let paidPostArr = JSON.parse(localStorage.getItem('paidPostArr'))||[];
        paidPostArr.push(formId);
        //去重
        let arr =new Set(paidPostArr)
        //将Set对象转为数组 使用了扩展运算符 再转为JSON串存到本地缓存
        localStorage.setItem('paidPostArr', JSON.stringify([...arr]));
        //刷新页面
        window.location.reload();
      }else{
             this.$dialog.confirm({
                title: '提示',
                message: '您没有足够的月亮或星星',
                confirmButtonText: '前往充值',	// 确认按钮文案	string	确认
                confirmButtonColor: "#3abaf7"	// 确认按钮颜色
              })
                .then(() => {
                  this.$router.push({
                    name:'paymenth5',
                    query:{
                        userid:this.$store.state.common.loginInfo.userId,
                        access_token:this.$store.state.common.loginInfo.access_token,
                        
                    }
                  })
                }).catch(() => {
                  // on cancel
                });
      }
    },
    // 判断是否已过期
    isOutmoded(str){
      if(str!==null&&str!==0){
        let dateStr=str.split('T')[0]
        let timeStr=dateStr+' 21:20:00'
        let date = new Date(timeStr)
        let nowDate=new Date()
        // console.log(date.getTime(),nowDate.getTime())
        if(date.getTime() <= nowDate.getTime())
        return true
      }
      // console.log(str)
      return false;
    },
    //判断是否已支付
    isPaid(formid){
      // let arr =[]
      // arr.push("1666246090572292096")
      // arr.push("1666247348975882240")
      // localStorage.setItem('paidPostArr', JSON.stringify(arr));
      let paidPostArr = JSON.parse(localStorage.getItem('paidPostArr'))||[];
      // console.log('@',paidPostArr,formid)
      if(paidPostArr!==null&&paidPostArr.indexOf(formid)!==-1){
        return true;
      }
      return false;
    },
    //判断是个为SVIP2、3
    async getsvipinfo(){
      const res = await this.$HTTP.get(this, this.$API['API_SVIP_INFO'],{userid : this.userId , system_id : this.systemId}, {}, true)
      if(res.statusCode == 200){
         res.data.forEach((item)=>{
          if((item.vipLevel===11||item.vipLevel===2)&&item.isAvailable){
            this.isSVIP23=true
          }
         })
        //  console.log('后台返回的svip列表',res.data,this.isSVIP23)

      }
    },
         // 收藏帖子（1）收藏（0）取消收藏
    async goCollect(item) {
      if (this.access_token) {
        const res = await this.$axios({
          method: "put",
          url: this.$API['API_FORUM_FAV_STATUS'] + '?forum_id=' + item.forumId + '&status=1',
          data: { forum_id: item.forumId, status: '1' },
          headers: { access_token: this.access_token }
        })
        if (res.data && res.data.statusCode === 200) {
          this.$toast('收藏成功');
        }
      } else {
        this.goLoginConfirm()
      }
    },
    // 点赞（1）点踩（2） 或 取消（3）
    async goThumbsSts(item, index) {
      if (this.access_token) {
        const res = await this.$axios({
          method: "put",
          url: this.$API['API_FORUM_THUMBS_STS'] + '?forum_id=' + item.forumId + '&status=1',
          data: { forum_id: item.forumId, status: '1' },
          headers: { access_token: this.access_token }
        })
        if (res.data && res.data.statusCode === 200) {
          this.list[index].upCount = '1'
          this.$toast('点赞成功');
        }
      } else {
        this.goLoginConfirm()
      }
    },
    // 使用正则实现html解码
    htmlDecodeByRegExp(str) {
      var s = '';
      if (str.length === 0) {
        return '';
      }
      s = str.replace(/&amp;/g, '&');
      s = s.replace(/&lt;/g, '<');
      s = s.replace(/&gt;/g, '>');
      s = s.replace(/&nbsp;/g, ' ');
      s = s.replace(/&#39;/g, '\'');
      s = s.replace(/&quot;/g, '\"');
      return s;
    },
    // ImagePreview 图片预览
    goImagePreview(imagesUrlArray, index) {
      ImagePreview({
        images: imagesUrlArray,
        startPosition: index,
        closeable: true,
      });
    },
    goLoginConfirm() {
      this.$dialog.confirm({
        title: '提示',
        message: '您还未注册登录',
        confirmButtonText: '免费注册登录',	// 确认按钮文案	string	确认
        confirmButtonColor: "#3abaf7"	// 确认按钮颜色
      })
        .then(() => {
          // on confirm
          this.goPage('login')
        })
        .catch(() => {
          // on cancel
        });
    },
    goShare(){
      wxShare.goShare({
        shareTitle: '测试标题',
        shareText: '测试内容',
        shareImgUrl: '/logo.jpg',
        shareUrl: document.URL
      });
    }
    },
    mounted(){
        // console.log(this.list)
        // console.log(this.list)
        this.getsvipinfo()
    }
}
</script>

<style lang="less" scoped>
.red{
  color: red;

}
.black{
  color: black;
}
  .list_box {
    .list {
      background-color: white;
      margin: 4px;
      padding: 10px 10px 0;
      p {
        margin: 0;
        font-size: 15px;
      }
      .head_bar {
        display: flex;
        align-items: center;
        .photo {
          width: 40px;
          height: 40px;
        }
        .name {
          font-size: 18px;
          margin-left: 10px;
          flex: 1;
        }
        .icon_box {
          display: flex;
          .icon {
            background-color: #e5e5e5;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 12px;
          }
          .icon:active {
            color: white;
            background-color: #fb3e44;
          }
        }
      }
      .postTime {
        color: #999;
        margin-left: 10px;
      }
      .rateReturn{
        // margin-left: 3.8rem;
        font-size: 0.3rem;
        margin-right: 0.3rem;
        float: right;
      }
      .contents {
        padding-bottom: 10px;
        .photo_box {
          margin-top: 12px;
          .photo {
            width: 72px;
            height: 72px;
            margin: 0 8px 2px 0;
          }
        }
      }
      .foot_bar {
        display: flex;
        justify-content: space-around;
        .icon {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          font-size: 15px;
          color: #999;
        }
        .Selected {
          color: #3abaf7;
          .van-icon {
            color: #3abaf7 !important;
          }
        }
      }
    }
  }

</style>